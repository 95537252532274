import React from 'react'
import { Card, Icon } from 'semantic-ui-react'
import { T, withTranslators } from 'lioness'

const OptionCard = ({ title, details, selected, price, onClick, t, dataTestId, noMargin }) => (
	<Card
		link
		color={selected ? 'green' : null}
		onClick={onClick}
		fluid
		data-testid={dataTestId}
		className={noMargin ? 'noMargin' : ''}
	>
		<Card.Content>
			<Card.Header>
				{title}
				{selected && (
					<p style={{ position: 'absolute', top: 10, right: 10 }}>
						<Icon circular inverted color="green" size="small" name="checkmark" data-testid="checkmark-icon" />
					</p>
				)}
			</Card.Header>
			<Card.Description>{details}</Card.Description>
			{price && (
				<Card.Meta>
					<T>Payment required</T>
				</Card.Meta>
			)}
		</Card.Content>
	</Card>
)

export default withTranslators(OptionCard)
