import React from 'react'
import { getLocalisedLanguageName } from '../utils/getLocalisedLanguageName'

const ChangeLocale = ({ clinicLocales, currentLocale }) => {
	const handleLocaleChange = locale => {
		const newUrl = new URL(window.location)
		newUrl.searchParams.set('l', locale)
		window.location.replace(newUrl.href)
	}

	return (
		<>
			{clinicLocales.map(({ localeId, locale, language }) => (
				<button key={localeId} onClick={() => handleLocaleChange(locale)}>
					{getLocalisedLanguageName(currentLocale, language)}
				</button>
			))}
		</>
	)
}

export default ChangeLocale
