import React, { lazy, memo, Suspense } from 'react'

const DevPanel = memo(({ clinicTheme }) => {
	if (['Local', 'Dev'].includes(window.CM_SCHEDULER_ENV.ENVIRONMENT)) {
		const DevPanelDropdown = lazy(() => import('./DevPanelDropdown'))
		return (
			<Suspense fallback={<></>}>
				<DevPanelDropdown clinicTheme={clinicTheme} />
			</Suspense>
		)
	}
	return null
})
export default DevPanel
