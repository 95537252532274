import React from 'react'
import { Button } from 'semantic-ui-react'
import { T } from 'lioness'

const BackButton = ({ onClick, disabled }) => (
	<Button basic color="grey" disabled={disabled} onClick={onClick}>
		<Button.Content>
			<T>Back</T>
		</Button.Content>
	</Button>
)

export default BackButton
