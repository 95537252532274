import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import { T } from 'lioness'

const errorStyles = StyleSheet.create({
	container: {
		paddingTop: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})

function Error({ title, description }) {
	if (!title && !description) {
		return (
			<div className={css(errorStyles.container)}>
				<div>
					<h1>
						<T>Error</T>
					</h1>
					<p>
						<T>The page couldn’t be opened. Please check whether the correct link was opened.</T>
					</p>
				</div>
			</div>
		)
	}

	return (
		<div className={css(errorStyles.container)}>
			<div>
				<h1>{title}</h1>
				<p>{description}</p>
			</div>
		</div>
	)
}

export default Error
