import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

const SearchIconButton = ({ onClick, displaySearchIcon }) => (
	<Button basic icon color="grey" onClick={onClick} style={{ visibility: displaySearchIcon ? 'inherit' : 'hidden' }}>
		<Icon name="search" />
	</Button>
)

export default SearchIconButton
