import React from 'react'
import { Button } from 'semantic-ui-react'
import { T } from 'lioness'

const NextButton = ({ onClick, disabled }) => (
	<Button primary disabled={disabled} onClick={onClick}>
		<Button.Content>
			<T>Next</T>
		</Button.Content>
	</Button>
)

export default NextButton
