import React, { useEffect } from 'react'
import Loader from '../components/Loader'
import { useReplaceUrl } from '../hooks'
import { redirectToUrl } from '../utils/url'

function BookingPayment(props) {
	useReplaceUrl(props.history.location.pathname)

	useEffect(() => {
		if (props.prepaymentRequired && props.prepaymentUrl) {
			redirectToUrl(props.prepaymentUrl)
		}
	}, [props.prepaymentRequired, props.prepaymentUrl])

	return <Loader />
}

export default BookingPayment
