import React from 'react'
import { Loader as SUILoader } from 'semantic-ui-react'

const Loader = ({ children, inline }) => {
	return (
		<>
			{inline ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<SUILoader active inline size="tiny" style={{ marginRight: 5 }} />
					{children}
				</div>
			) : (
				<SUILoader active inline="centered">
					{children}
				</SUILoader>
			)}
		</>
	)
}

export default Loader
