import request from 'superagent'
import ReactGA from 'react-ga'
import ReactGA_4 from 'react-ga4'

async function requestAppointment({
	initials,
	firstName,
	lastName,
	sex,
	dateOfBirth,
	mobile,
	email,
	start,
	serviceId,
	treatmentId,
	locationUuid,
	clinicUuid,
	doctorId,
	appointmentType,
	newsletter,
	note,
	discountCode,
	recaptchaToken,
}) {
	const res = (
		await request
			.post('/api/request-appointment')
			.type('form')
			.send({ start: start.format('YYYY-MM-DD HH:mm:ss') })
			.send({ mobile })
			.send({ lastname: lastName })
			.send({ service_id: serviceId })
			.send({ treatment_id: treatmentId })
			.send({ location_uuid: locationUuid })
			.send({ clinic_uuid: clinicUuid })
			.send({ user_id: doctorId })
			.send({ firstname: firstName })
			.send({
				appointment_type: {
					CONSULTATION: 2,
					CONSULTATION_TREATMENT: 12,
					TREATMENT: 3,
					INSPECTION: 4,
					AFTERTREATMENT: 5,
					VIDEO_CONSULTATION: 10,
					VIDEO_INSPECTION: 11,
					PHONE_CALL: 7,
				}[appointmentType],
			})
			.send({ date_of_birth: dateOfBirth.format('YYYY-MM-DD') })
			.send({ email })
			.send({
				sex: {
					male: 1,
					female: 2,
					other: 9,
				}[sex],
			})
			.send({ initials })
			.send({ newsletter })
			.send({ note })
			.send({ discount_code: discountCode })
			.send({ recaptchaToken })
	).body

	request
		.post('/api/a7s-user-id')
		.type('form')
		.send({ clinic_uuid: clinicUuid })
		.send({ date_of_birth: dateOfBirth.format('YYYY-MM-DD') })
		.send({ phone: mobile })
		.then(({ body }) => {
			if (body.user_id !== null) {
				ReactGA.set({ userId: body.user_id })
				ReactGA_4.set({ userId: body.user_id })
			}
		})

	return {
		success: res.success,
		error: res.error,
		appointmentRequestId: res.appointment_request_id,
		skipTokenConfirmation: res.skip_token_confirmation,
	}
}

async function changeMobile({ locationUuid, clinicUuid, appointmentRequestId, dateOfBirth, mobile }) {
	const res = (
		await request
			.post('/api/change-mobile-number')
			.type('form')
			.send({ clinic_uuid: clinicUuid })
			.send({ location_uuid: locationUuid })
			.send({ appointment_request_id: appointmentRequestId })
			.send({ mobile })
	).body

	request
		.post('/api/a7s-user-id')
		.type('form')
		.send({ clinic_uuid: clinicUuid })
		.send({ date_of_birth: dateOfBirth.format('YYYY-MM-DD') })
		.send({ phone: mobile })
		.then(({ body }) => {
			if (body.user_id !== null) {
				ReactGA.set({ userId: body.user_id })
				ReactGA_4.set({ userId: body.user_id })
			}
		})

	return {
		success: res.success,
		error: res.error,
	}
}

async function verifyAppointment({ locationUuid, clinicUuid, appointmentRequestId, token }) {
	const res = (
		await request
			.post('/api/verify-appointment')
			.type('form')
			.send({ clinic_uuid: clinicUuid })
			.send({ location_uuid: locationUuid })
			.send({ appointment_request_id: appointmentRequestId })
			.send({ token })
	).body

	return {
		success: res.success,
		error: res.error,
		prepaymentRequired: res.prepayment_required,
		prepaymentAmount: res.prepayment_amount,
		prepaymentUrl: res.prepayment_url,
	}
}

async function bookingDetails({ clinicUuid, prepaymentUuid }) {
	const res = (
		await request.get(
			`/api/booking-details?
			clinic_uuid=${clinicUuid}&
			prepayment_uuid=${prepaymentUuid}`
		)
	).body

	return {
		success: res.success,
		error: res.error,
		locationUuid: res.location_uuid,
		serviceId: res.service_id,
		appointmentType: res.appointment_type,
	}
}

async function resendAppointmentToken({ locationUuid, clinicUuid, appointmentRequestId }) {
	const res = (
		await request
			.post('/api/resend-appointment-token')
			.type('form')
			.send({ clinic_uuid: clinicUuid })
			.send({ location_uuid: locationUuid })
			.send({ appointment_request_id: appointmentRequestId })
	).body

	return {
		success: res.success,
		error: res.error,
	}
}

async function cancelAppointment({ clinicUuid, cancellationToken }) {
	const res = (
		await request
			.post('/api/cancel-appointment')
			.type('form')
			.send({ clinic_uuid: clinicUuid })
			.send({ appointment_token: cancellationToken })
	).body

	request
		.post('/api/a7s-user-id')
		.type('form')
		.send({ clinic_uuid: clinicUuid })
		.send({ appointment_token: cancellationToken }) // TODO We really should align our terminology...
		.then(({ body }) => {
			if (body.user_id !== null) {
				ReactGA.set({ userId: body.user_id })
				ReactGA_4.set({ userId: body.user_id })
			}
		})

	return {
		success: res.success,
		error: res.error,
		refund_option: res.refund_option,
		refund_amount: res.refund_amount,
		prepayment_amount: res.prepayment_amount,
		fee_amount: res.fee_amount,
	}
}

async function requestRefund({ clinicUuid, cancellationToken }) {
	const res = (
		await request
			.post('/api/request-refund')
			.type('form')
			.send({ clinic_uuid: clinicUuid })
			.send({ appointment_token: cancellationToken })
	).body

	return {
		success: res.success,
		error: res.error,
	}
}

async function rescheduleAppointment({
	clinicUuid,
	cancellationToken,
	serviceId,
	locationUuid,
	doctorId,
	appointmentType,
	start,
}) {
	const res = (
		await request
			.post('/api/reschedule-appointment')
			.type('form')
			.send({ clinic_uuid: clinicUuid })
			.send({ appointment_token: cancellationToken })
			.send({ service_id: serviceId })
			.send({ location_uuid: locationUuid })
			.send({ user_id: doctorId })
			.send({
				appointment_type: {
					CONSULTATION: 2,
					CONSULTATION_TREATMENT: 12,
					TREATMENT: 3,
					INSPECTION: 4,
					AFTERTREATMENT: 5,
					VIDEO_CONSULTATION: 10,
					VIDEO_INSPECTION: 11,
					PHONE_CALL: 7,
				}[appointmentType],
			})
			.send({ start: start.format('YYYY-MM-DD HH:mm:ss') })
	).body

	request
		.post('/api/a7s-user-id')
		.type('form')
		.send({ clinic_uuid: clinicUuid })
		.send({ appointment_token: cancellationToken })
		.then(({ body }) => {
			if (body.user_id !== null) {
				ReactGA.set({ userId: body.user_id })
				ReactGA_4.set({ userId: body.user_id })
			}
		})

	return {
		success: res.success,
		error: res.error,
	}
}

export {
	requestAppointment,
	changeMobile,
	verifyAppointment,
	bookingDetails,
	resendAppointmentToken,
	cancelAppointment,
	requestRefund,
	rescheduleAppointment,
}
