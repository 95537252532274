import './support'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { ApolloProvider } from 'react-apollo'
import catchError from './catchError'
import ApolloClientSingleton from '../network/ApolloClientSingleton'
import App from '../components/App'
import * as Sentry from '@sentry/react'
import './styles/semantic/semantic.css'
import './styles/semantic-overrides.css'
import './styles/main.css'
import Loader from '../components/Loader'
import { createBrowserHistory } from 'history'
import { isGoogleTagManagerError } from '../utils/isGoogleTagManagerError'

const history = createBrowserHistory()

// Sentry setup
if (!!window.CM_SCHEDULER_ENV.SENTRY_FRONTEND_DSN) {
	Sentry.init({
		dsn: window.CM_SCHEDULER_ENV.SENTRY_FRONTEND_DSN,
		release: window.CM_SCHEDULER_ENV.SENTRY_RELEASE,
		environment: window.CM_SCHEDULER_ENV.ENVIRONMENT,
		attachStacktrace: true,
		normalizeDepth: 6,

		beforeSend(event, hint) {
			// filter errors from google tag manager
			try {
				if (isGoogleTagManagerError(hint.originalException)) {
					return null
				}
			} catch (e) {
				console.error(e)
			}
			return event
		},
		integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
		enableTracing: true,
		tracesSampleRate: 0, // no performance monitoring
	})
}

// Gracefully handle unhandled errors and rejections
window.onerror = (msg, file, line, col, error) => catchError(error)
window.addEventListener('unhandledrejection', event => catchError(event.reason))

ReactDOM.render(
	// data-iframe-height required by iFrameResizer
	<div data-iframe-height>
		<ApolloProvider client={ApolloClientSingleton}>
			<Suspense fallback={<Loader />}>
				<Router history={history}>
					<App query={window.location.search.slice(1)} path={window.location.pathname} />
				</Router>
			</Suspense>
		</ApolloProvider>
	</div>,
	document.getElementById('mount')
)

// Enable hot module replacement
if (module.hot) {
	module.hot.accept()
}
