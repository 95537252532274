import { getFullUrlRelative, getPathUrlRelative } from './url'

// Run the callback function after the FB Pixel tracker is loaded
function runAfterFbLoaded(callback) {
	const fbLoaded = () => window.fbq && window.fbq.version > '2.0'

	if (fbLoaded()) {
		callback()
	} else {
		var tries = 0

		const interval = setInterval(() => {
			if (fbLoaded()) {
				// If the FB Pixel tracker was just loaded, we wait some extra
				// time so it can complete initialization
				setTimeout(callback, 200)

				clearInterval(interval)
				return
			}

			// Stop trying after 50 times (5 seconds)
			if (++tries > 50) {
				clearInterval(interval)
			}
		}, 100)
	}
}

// Run the callback function with a 'fake' path (using history.replaceState)
function runWithPath(callback, path) {
	const originalPath = getFullUrlRelative()
	window.history.replaceState(null, null, path)
	callback()
	window.history.replaceState(null, null, originalPath)
}

export function fbTrack(callback, pathOverride) {
	const trackingPath = getPathUrlRelative(pathOverride)

	// Delay until FB Pixel is loaded, with 'fake' tracking path. Without delay,
	// tracking gets queued and executed later, which causes the path hack to be
	// already reverted at the time tracking occurs.
	runAfterFbLoaded(() => runWithPath(callback, trackingPath))
}
