import { checkIosVersion } from './checkIosVersion'

export const scrollToHeight = (scrollHeight, timeout = 400) => {
	const iOsVersion = checkIosVersion()

	setTimeout(() => {
		// Limit scroll position to the top of the page.
		scrollHeight = Math.max(scrollHeight, 0)

		if ('parentIFrame' in window) {
			// Limit scroll position to bottom of the page.
			// In `widget.js`, we further limit the scroll position so the iframe
			// containing the scheduler doesn't get scrolled out of the viewport of
			// the parent page.
			scrollHeight = Math.min(scrollHeight, document.body.scrollHeight)

			window.parentIFrame.scrollToOffset(0, scrollHeight)
		} else if (iOsVersion === 0 || iOsVersion >= 13 || window.top === window.self) {
			// Limit scroll position to bottom of the page minus the viewport height
			// (so the lowest position you could scroll without going past the bottom).
			// Without this, iOS 15.4+ would scroll past the bottom of the page when
			// the scheduler is in the widget overlay (iframe with fixed positioning),
			// showing only a white screen.
			scrollHeight = Math.min(scrollHeight, document.body.scrollHeight - document.documentElement.clientHeight)

			window.scrollTo({
				left: 0,
				top: scrollHeight,
				behavior: 'smooth',
			})
		}
	}, timeout)
}

export const scrollToBottom = (timeout = 400) => {
	scrollToHeight(document.body.scrollHeight, timeout)
}
