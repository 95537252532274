import { injectGlobal } from '@emotion/css'
import { darken, lighten, mix, transparentize } from 'polished'

export default function applyTheme({ colorBackground, colorPrimary, colorSecondary, colorText }) {
	injectGlobal`
		/* global */
		body {
			color: ${colorText};
			background: ${colorBackground};
		}

		/* Workaround for using the theme switched on DEV! I'm not using the table tag so this does not affect any styles. */
		.__theme_refresh_workaround {
			border-image-width: ${Math.random()};
		}

		/* header */
		.ui.header {
			color: ${colorText};
		}
		h2.ui.header .sub.header {
			color: ${transparentize(0.4, colorText)};
		}

		/* card */
		.ui.cards > .card, .ui.card, .ui.cards a.card:hover, .ui.link.cards .card:hover, a.ui.card:hover, .ui.link.card:hover {
			background-color: ${colorBackground};
			box-shadow:
				0px 1px 3px 0px ${transparentize(0.8, colorText)},
				0px 0px 0px 1px ${transparentize(0.8, colorText)};
		}
		.ui.green.cards > .card, .ui.cards > .green.card, .ui.green.card, .ui.green.cards > .card:hover, .ui.cards > .green.card:hover, .ui.green.card:hover {
			box-shadow:
				0px 0px 0px 1px ${transparentize(0.8, colorText)},
				0px 2px 0px 0px ${transparentize(0.4, colorSecondary)},
				0px 1px 3px 0px ${transparentize(0.8, colorText)};
		}
		.ui.cards > .card > .content > .header, .ui.card > .content > .header {
			color: ${colorText};
		}
		i.inverted.bordered.green.icon, i.inverted.circular.green.icon {
			background-color: ${colorSecondary} !important;
		}
		.description {
			color: ${colorText} !important;
		}
		.ui.cards > .card > .extra, .ui.card > .extra {
			color: ${transparentize(0.4, colorText)};
			border-top-color: ${transparentize(0.6, colorText)} !important;
		}

		.ui.cards > .card .meta, .ui.card .meta {
			color: ${transparentize(0.4, colorText)}
		}

		/* button */
		.ui.button {
			color: ${colorText};
			background-color: ${transparentize(0.85, colorText)};

			&:hover {
				color: ${transparentize(0.2, colorText)};
				background-color: ${transparentize(0.8, colorText)} !important;
			}
			&:active {
				background-color: ${transparentize(0.75, colorText)} !important;
			}
		}
		.ui.list .list > .item .header, .ui.list > .item .header {
			color: ${colorText};
		}
		.ui.buttons .or:before {
			color: ${transparentize(0.6, colorText)};
			background-color: ${colorBackground} !important;
		}

		/* primary button */
		.ui.primary.buttons .button, .ui.primary.button {
			background-color: ${colorPrimary};

			&:hover {
				background-color: ${darken(0.06, colorPrimary)} !important;
			}
			&:active {
				background-color: ${darken(0.1, colorPrimary)} !important;
			}
		}

		/* secondary button */
		.ui.secondary.buttons .button, .ui.secondary.button {
			color: ${colorBackground};
			background-color: ${lighten(0.05, colorText)};

			&:hover {
				color: ${colorBackground};
				background-color: ${darken(0.06, colorText)} !important;
			}
			&:active {
				color: ${colorBackground};
				background-color: ${darken(0.1, colorText)} !important;
			}
		}

		/* toggle button */
		.ui.toggle.buttons .active.button, .ui.buttons .button.toggle.active, .ui.button.toggle.active {
			background-color: ${colorSecondary} !important;
		}
		.ui.button.toggle.active {
			&:hover {
				background-color: ${darken(0.05, colorSecondary)} !important;
			}
		}

		/* basic button */
		.ui.basic.buttons .button, .ui.basic.button, .ui.basic.grey.buttons .button, .ui.basic.grey.button {
			color: ${transparentize(0.4, colorText)} !important;
			box-shadow: 0px 0px 0px 1px ${transparentize(0.8, colorText)} inset !important;

			&:hover {
				color: ${transparentize(0.2, colorText)} !important;
				box-shadow: 0px 0px 0px 1px ${transparentize(0.5, colorText)} inset !important;
				background: transparent !important;
			}
			&:active {
				color: ${transparentize(0.1, colorText)} !important;
				box-shadow: 0px 0px 0px 1px ${transparentize(0.4, colorText)} inset !important;
			}
		}

		/* divider */
		.ui.divider {
			color: ${colorText};
		}

		/* progress bar */
		.ui.progress {
			background-color: ${transparentize(0.9, colorText)};
		}
		.ui.progress .bar {
			background-color: ${transparentize(0.5, colorText)};
		}

		/* message */
		.ui.info.message {
			box-shadow:
				0px 0px 0px 1px ${transparentize(0.6, colorPrimary)} inset,
				0px 0px 0px 0px rgba(0, 0, 0, 0);
			background-color: ${transparentize(0.96, colorPrimary)};
			color: ${colorText};
		}

		.ui.info.message .header {
			color: ${colorPrimary}
		}

		/* label */
		.ui.label {
			color: ${colorText};
			background-color: ${transparentize(0.9, colorText)};
		}
		.ui.grey.labels .label, .ui.grey.label {
			color: ${lighten(0.9, colorText)} !important;
			background-color: ${transparentize(0.4, colorText)} !important;
		}

		/* dropdown */
		.ui.selection.dropdown {
			background-color: ${colorBackground};
			border-color: ${transparentize(0.8, colorText)};
			color: ${colorText};
		}
		.ui.selection.dropdown:hover, .ui.selection.active.dropdown:hover, .ui.selection.active.dropdown {
			border-color: ${transparentize(0.4, colorText)};
		}
		.ui.selection.active.dropdown {
			border-bottom: 0;
		}
		.ui.dropdown .menu {
			background-color: ${colorBackground};
			border-top: 0;
			border-color: ${transparentize(0.4, colorText)};
		}
		.ui.selection.active.dropdown .menu, .ui.selection.active.dropdown:hover .menu {
			border-color: ${transparentize(0.4, colorText)};
		}
		.ui.selection.active.dropdown .menu {
			box-shadow: 0px 2px 3px 0px ${transparentize(0.85, colorText)};
		}
		.ui.dropdown.selected, .ui.dropdown .menu .selected.item {
			color: ${colorText};
			background-color: ${transparentize(0.97, colorText)};
			border: none;
		}
		.ui.dropdown .menu > .item {
			color: ${colorText};
			border: none !important;
			font-size: inherit
		}
		.ui.dropdown .menu > .item:hover {
			color: ${transparentize(0.2, colorText)};
			background-color: ${transparentize(0.95, colorText)};
		}
		.ui.selection.visible.dropdown > .text:not(.default), .ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text {
			color: unset;
		}
		.ui.native-dropdown {
			line-height: 38px;
			padding: 0 !important;
			& .icon {
				pointer-events: none;
			}
		&>select {
			color: ${colorText};
			width: 100%;
			display: block !important;
			border: 0;
			background: none;
			outline: none;
			margin: 0;
				padding: 0 0.7em !important;
				line-height: 38px;
				-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		}

		/* form label */
		.ui.form .field > label, .ui.checkbox label, .ui.checkbox + label, .ui.checkbox label:hover, .ui.checkbox + label:hover, .ui.checkbox input:active ~ label {
			color: ${transparentize(0.13, colorText)};
		}

		/* checkbox */

		.ui.checkbox {
			font-size: inherit
		}

		.ui.checkbox input:focus ~ label {
			color: ${colorText}
		}

		/* input */

		.ui.icon.searchInput > i.icon.search {
			color: ${colorText}
		}

		.ui.form .field.field input:-webkit-autofill, .ui.form .field.field input:-webkit-autofill:focus, .ui.form .error.error input:-webkit-autofill {
			-webkit-box-shadow: initial !important;
			box-shadow: initial;
			background-color: initial !important;
			border-color: ${transparentize(0.8, colorText)} !important;

			&:focus {
				box-shadow: 0 0 0 2px ${transparentize(0.4, colorPrimary)};
			}
		}

		.ui.input > input, .ui.input > input:focus, .ui.form textarea, .ui.form textarea:focus, .ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"], .ui.form input:not([type]):focus, .ui.form input[type="date"]:focus, .ui.form input[type="datetime-local"]:focus, .ui.form input[type="email"]:focus, .ui.form input[type="number"]:focus, .ui.form input[type="password"]:focus, .ui.form input[type="search"]:focus, .ui.form input[type="tel"]:focus, .ui.form input[type="time"]:focus, .ui.form input[type="text"]:focus, .ui.form input[type="file"]:focus, .ui.form input[type="url"]:focus {
			background-color: ${colorBackground};
			border-color: ${transparentize(0.8, colorText)};
			color: ${colorText};
			&:focus {
				border-color: ${transparentize(0.4, colorPrimary)};
				box-shadow: 0 0 0 2px ${transparentize(0.4, colorPrimary)};
			}
		}
		.ui.form .field.error label, .ui.form label.error {
			color: inherit;
			&:after {
				padding: 1px 4px;
				margin-left: 10px;
				content: "ERROR";
				background: ${darken(0.2, 'red')};
				border-radius: 3px;
				color: white;
			}
		}
		.ui.form .fields.error .field textarea, .ui.form .fields.error .field select, .ui.form .fields.error .field input:not([type]), .ui.form .fields.error .field input[type="date"], .ui.form .fields.error .field input[type="datetime-local"], .ui.form .fields.error .field input[type="email"], .ui.form .fields.error .field input[type="number"], .ui.form .fields.error .field input[type="password"], .ui.form .fields.error .field input[type="search"], .ui.form .fields.error .field input[type="tel"], .ui.form .fields.error .field input[type="time"], .ui.form .fields.error .field input[type="text"], .ui.form .fields.error .field input[type="file"], .ui.form .fields.error .field input[type="url"], .ui.form .field.error textarea, .ui.form .field.error select, .ui.form .field.error input:not([type]), .ui.form .field.error input[type="date"], .ui.form .field.error input[type="datetime-local"], .ui.form .field.error input[type="email"], .ui.form .field.error input[type="number"], .ui.form .field.error input[type="password"], .ui.form .field.error input[type="search"], .ui.form .field.error input[type="tel"], .ui.form .field.error input[type="time"], .ui.form .field.error input[type="text"], .ui.form .field.error input[type="file"], .ui.form .field.error input[type="url"] {
				background-color: ${colorBackground} !important;
				border-color: ${darken(0.2, 'red')} !important;
				box-shadow: 0 0 0 2px rgba(153,0,0,0.5);
				color: ${colorText} !important;
				&:focus {
					border-color: ${transparentize(0.4, colorPrimary)} !important;
					box-shadow: 0 0 0 2px ${transparentize(0.4, colorPrimary)};
				}
		}
		::placeholder {
			color: ${transparentize(0.13, colorText)} !important;
			opacity: 0.54
		}
		.ui.labeled.input {
			background-color: ${colorBackground};
			border: 1px solid ${transparentize(0.8, colorText)};
			border-radius: 5px;
			&:focus-within {
				border-color: ${transparentize(0.4, colorPrimary)};
				box-shadow: 0 0 0 2px ${transparentize(0.4, colorPrimary)} !important;
			}
			input, .ui.basic.label, input:focus {
				border: 0 !important;
				background: none !important;
				box-shadow: none !important;
			}
		}
		div.error {
			.ui.labeled.input {
				border: 1px solid ${darken(0.2, 'red')};
				box-shadow: 0 0 0 2px rgba(153,0,0,0.5);
				&:focus-within {
					border-color: ${transparentize(0.4, colorPrimary)} !important;
					box-shadow: 0 0 0 2px ${transparentize(0.4, colorPrimary)} !important;
				}
				input, .ui.basic.label, input:focus {
					border: 0 !important;
					background: none !important;
					box-shadow: none !important;
				}
			}
		}
		.ui.basic.label {
			background-color: ${colorBackground};
			border-color: ${transparentize(0.4, colorText)};
		}
		.ui.basic.blue.label {
			background: none ${colorBackground} !important;
			color: ${colorPrimary} !important;
			border-color: ${colorPrimary} !important;
		}

		.searchInput {
			width: 100%
		}

		.ui[class*="searchInput"].input > input {
			padding-right: 2.67142857em !important;
		}

		/* pointing */
		.ui.pointing.label:not(.basic) {
			background-color: ${mix(0.1, colorText, colorBackground)};
			color: ${colorText};

			button {
				opacity: 1;
				color: ${colorPrimary} !important;
				text-decoration-color: ${colorPrimary} !important;
				text-decoration-line: underline;
				background-color: initial;
				padding: 0;
				margin: 0;
				font-weight: 700;
				border: none;
				box-shadow: none;
				cursor: pointer;


				&:hover {
					color: ${darken(0.1, colorPrimary)} !important;
				}
			}
		}

		/* popup */
		.ui.popup {
			color: ${transparentize(0.13, colorText)};
			background-color: ${colorBackground};
			box-shadow:
				0px 2px 4px 0px ${transparentize(0.88, colorText)},
				0px 2px 10px 0px ${transparentize(0.85, colorText)};
			border-color: ${transparentize(0.8, colorText)};
		}
		.ui.popup:before {
			background-color: ${colorBackground} !important;
			box-shadow: 1px 1px 0px 0px ${transparentize(0.8, colorText)};
		}
		.ui.bottom.left.popup:before {
			box-shadow: -1px -1px 0px 0px ${transparentize(0.8, colorText)};
		}

		/* modal */
		.ui.modal > .header, .ui.modal > .content, .ui.modal > .actions {
			background-color: ${colorBackground};
		}
		.ui.modal > .header {
			color: ${transparentize(0.15, colorText)};
			border-bottom-color: ${transparentize(0.85, colorText)};
		}
		.ui.modal > .actions {
			border-top-color: ${transparentize(0.85, colorText)};
		}
		.ui.dimmer {
			background-color: ${transparentize(0.15, colorBackground)};
		}

		.ui.modal {
			box-shadow: 1px 3px 3px 0px ${transparentize(0.8, colorText)}, 1px 3px 15px 2px ${transparentize(0.8, colorText)}
		}

	a.link {
	  opacity: 1;
	  color: ${colorPrimary} !important;
	  background-color: initial;

	  &:hover {
		color: ${darken(0.1, colorPrimary)} !important;
	  }
	}

	// IntlTelInput
	.iti {
		--iti-hover-color: ${transparentize(0.95, colorText)};
		--iti-text-gray: ${transparentize(0.4, colorText)};
		--iti-border-gray: ${transparentize(0.4, colorText)};
		--iti-arrow-color: ${transparentize(0.2, colorText)};
	}
	.iti__selected-country:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 3px ${transparentize(0.4, colorPrimary)};
	}
	.iti__flag {
		box-shadow: 0px 0px 1px 0px ${transparentize(0.6, colorText)} !important;
	}
	.iti__dropdown-content{
		background-color: ${colorBackground} !important;
	}
	.iti--fullscreen-popup.iti--container {
		background-color: ${transparentize(0.15, colorText)} !important;
	}
	.iti--inline-dropdown .iti__dropdown-content {
		box-shadow: 0px 2px 3px 0px ${transparentize(0.85, colorText)} !important;
	}
	.iti__country.iti__highlight {
		color: ${transparentize(0.2, colorText)} !important;
	}
	`
}
