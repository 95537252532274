import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import Loader from '../components/Loader'

class Demo extends React.Component {
	render() {
		const { data, website } = this.props
		if (data?.loading) {
			return <Loader />
		}

		return (
			<div>
				<p>website={website}</p>
			</div>
		)
	}
}

const query = gql`
	query ($clinicUuid: ID!) {
		clinic(uuid: $clinicUuid) {
			id
			locations {
				uuid
			}
		}
	}
`

export default graphql(query, {
	options: props => ({
		variables: { clinicUuid: props.clinicUuid },
		notifyOnNetworkStatusChange: true,
	}),
})(Demo)
