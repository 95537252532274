import React from 'react'
import { Progress } from 'semantic-ui-react'

const StepIndicator = ({ step, requireTreatment, requireLocation }) => {
	let totalStep = PageStep.BOOKED
	let currentStep = step
	if (!requireTreatment) {
		if (step >= PageStep.TREATMENT) {
			--currentStep
		}
		--totalStep
	}
	if (!requireLocation) {
		if (step >= PageStep.LOCATION) {
			--currentStep
		}
		--totalStep
	}
	return (
		<Progress
			value={currentStep}
			total={totalStep}
			size="small"
			// Hack to make it lighter, should modify color in theme later
			disabled
			autoSuccess
		/>
	)
}

export const PageStep = Object.freeze({
	HOME: 1,
	APPOINTMENT: 2,
	TREATMENT: 3,
	LOCATION: 4,
	AVAILABILITY: 5,
	PERSONAL_DETAILS: 6,
	VERIFICATION: 7,
	BOOKED: 8,
})
export default StepIndicator
