import React, { useEffect, useState } from 'react'
import Loader from '../components/Loader'
import * as Clinic from '../network/clinic'
import { Redirect } from 'react-router-dom'

function PaidBookingComplete({ clinicUuid, prepaymentUuid, setBookedState }) {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchBookingDetails = async () => {
			const res = await Clinic.bookingDetails({
				clinicUuid,
				prepaymentUuid,
			})

			if (res.success) {
				setBookedState({
					locationUuid: res.locationUuid,
					serviceId: res.serviceId,
					appointmentType: res.appointmentType,
				})
				setLoading(false)
			} else {
				throw Error(res.error)
			}
		}
		fetchBookingDetails()
	}, [clinicUuid, prepaymentUuid, setBookedState])

	if (loading) {
		return <Loader />
	}

	return <Redirect to="/booked" />
}

export default PaidBookingComplete
