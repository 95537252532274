// AS42314 Fusion Internet Services Company LLC
export const blockedIpRanges = [
	'85.114.96.0/19',
	'85.114.96.0/24',
	'85.114.97.0/24',
	'85.114.98.0/24',
	'85.114.99.0/24',
	'85.114.100.0/24',
	'85.114.101.0/24',
	'85.114.102.0/24',
	'85.114.103.0/24',
	'85.114.104.0/24',
	'85.114.105.0/24',
	'85.114.106.0/24',
	'85.114.107.0/24',
	'85.114.108.0/24',
	'85.114.109.0/24',
	'85.114.110.0/24',
	'85.114.111.0/24',
	'85.114.112.0/24',
	'85.114.113.0/24',
	'85.114.114.0/24',
	'85.114.115.0/24',
	'85.114.116.0/24',
	'85.114.117.0/24',
	'85.114.118.0/24',
	'85.114.119.0/24',
	'85.114.120.0/24',
	'85.114.121.0/24',
	'85.114.122.0/24',
	'85.114.123.0/24',
	'85.114.124.0/24',
	'85.114.125.0/24',
	'85.114.126.0/24',
	'85.114.127.0/24',
	'185.97.64.0/24',
	'185.97.65.0/24',
	'185.97.66.0/24',
	'185.97.67.0/24',
	'188.225.251.0/24',
	'188.225.252.0/24',
	'188.225.253.0/24',
	'188.225.254.0/24',
]

// Nigeria
export const blockedPhoneNumberPrefixes = ['+234']

// Number is abused, owner does not want to get any bookings
// https://app.intercom.com/a/apps/n12r51gr/conversations/27253864129
export const blockedPhoneNumbers = ['+31615405530']

// Enable captcha in Local/Dev/Beta (for testing), and specific clinics (in Prod)
// We don't disable it in Test/Actions because it can sometimes fail
export const environmentsWithCaptcha = ['Local', 'Dev', 'Beta']
export const clinicUuidsWithCaptcha = ['6db9ed77-1641-11ec-a82a-0a07c683c4ad', '0df3fcb1-8148-11e9-858e-0a599d16f52a']
