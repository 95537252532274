import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { shouldPolyfill as shouldCanonicalPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldLocalePolyfill } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldDisplayNamesPolyfill } from '@formatjs/intl-displaynames/should-polyfill'
import { get } from 'lodash'
import moment from 'moment'

const momentAllLocales = [
	'en',
	'af',
	'ar-dz',
	'ar-kw',
	'ar-ly',
	'ar-ma',
	'ar-sa',
	'ar-tn',
	'ar',
	'az',
	'be',
	'bg',
	'bm',
	'bn-bd',
	'bn',
	'bo',
	'br',
	'bs',
	'ca',
	'cs',
	'cv',
	'cy',
	'da',
	'de-at',
	'de-ch',
	'de',
	'dv',
	'el',
	'en-au',
	'en-ca',
	'en-gb',
	'en-ie',
	'en-il',
	'en-in',
	'en-nz',
	'en-sg',
	'eo',
	'es-do',
	'es-mx',
	'es-us',
	'es',
	'et',
	'eu',
	'fa',
	'fi',
	'fil',
	'fo',
	'fr-ca',
	'fr-ch',
	'fr',
	'fy',
	'ga',
	'gd',
	'gl',
	'gom-deva',
	'gom-latn',
	'gu',
	'he',
	'hi',
	'hr',
	'hu',
	'hy-am',
	'id',
	'is',
	'it-ch',
	'it',
	'ja',
	'jv',
	'ka',
	'kk',
	'km',
	'kn',
	'ko',
	'ku',
	'ky',
	'lb',
	'lo',
	'lt',
	'lv',
	'me',
	'mi',
	'mk',
	'ml',
	'mn',
	'mr',
	'ms-my',
	'ms',
	'mt',
	'my',
	'nb',
	'ne',
	'nl-be',
	'nl',
	'nn',
	'oc-lnc',
	'pa-in',
	'pl',
	'pt-br',
	'pt',
	'ro',
	'ru',
	'sd',
	'se',
	'si',
	'sk',
	'sl',
	'sq',
	'sr-cyrl',
	'sr',
	'ss',
	'sv',
	'sw',
	'ta',
	'te',
	'tet',
	'tg',
	'th',
	'tk',
	'tl-ph',
	'tlh',
	'tr',
	'tzl',
	'tzm-latn',
	'tzm',
	'ug-cn',
	'uk',
	'ur',
	'uz-latn',
	'uz',
	'vi',
	'x-pseudo',
	'yo',
	'zh-cn',
	'zh-hk',
	'zh-mo',
	'zh-tw',
]

const getMomentLocaleName = localeCode => {
	const locale = new Intl.Locale(localeCode)
	const region = locale.region
	const language = locale.language
	if (region?.toLowerCase() === language?.toLowerCase()) {
		return language
	}
	if (momentAllLocales.includes(localeCode.toLowerCase())) {
		return localeCode.toLowerCase()
	} else {
		return language
	}
}

const I18NProvider = ({ locale, children, intlLocale }) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const simpleLocale = get(intlLocale.match(/^([a-z]+)-/), '[1]', 'en')

		const loadPolyfill = async () => {
			if (shouldCanonicalPolyfill()) {
				await import('@formatjs/intl-getcanonicallocales/polyfill')
			}
			if (shouldLocalePolyfill()) {
				await import('@formatjs/intl-locale/polyfill')
			}
			if (shouldDisplayNamesPolyfill()) {
				await import('@formatjs/intl-displaynames/polyfill-force')
				await import(`@formatjs/intl-displaynames/locale-data/${simpleLocale}`)
			}
		}

		loadPolyfill().then(() => {
			const momentLocaleName = getMomentLocaleName(intlLocale)
			import(`moment/locale/${momentLocaleName}`)
				.then(() => {
					moment.locale(momentLocaleName)
				})
				// set default locale if no locale was found
				.catch(() => moment.locale('en'))
				.finally(() => setLoaded(true))
		})
	}, [intlLocale])

	return loaded ? <IntlProvider locale={locale}>{children}</IntlProvider> : null
}

export default I18NProvider
