import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'node-fetch'
import qs from 'qs'

const ApolloClientSingleton = new ApolloClient({
	link: createHttpLink({
		uri: `/graphql?l=${qs.parse(window.location.search).l}`,
		fetch,
	}),
	cache: new InMemoryCache(),
	connectToDevTools: true,
})

export default ApolloClientSingleton
