import React from 'react'
import { Icon, Input } from 'semantic-ui-react'
import { withTranslators } from 'lioness'

const iconStyles = {
	position: 'absolute',
	top: '50%',
	right: '6px',
	transform: 'translateY(-50%)',
	textAlign: 'center',
}

function SearchInput({ onSearch, value, onClear, t }) {
	return (
		<div style={{ position: 'relative', flex: 1 }}>
			<Input
				icon="search"
				iconPosition="left"
				placeholder={t('Search treatment…')}
				onChange={onSearch}
				value={value}
				autoFocus
				className="searchInput"
				size="large"
			/>
			<Icon name="delete" onClick={onClear} inverted circular link size="small" style={iconStyles} color="grey" />
		</div>
	)
}

export default withTranslators(SearchInput)
