export const getPathUrlRelative = pathOverride => pathOverride || window.location.pathname

export const getFullUrlRelative = pathOverride => getPathUrlRelative(pathOverride) + window.location.search

export const getPathUrl = pathOverride =>
	window.location.protocol + '//' + window.location.host + getPathUrlRelative(pathOverride)

export const redirectToUrl = url => {
	window.top.location.href = url
}
